import React, { useEffect, useState } from 'react';
import { App, Modal } from 'antd';
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { NavBar, OvalLoading, AppErrorBoundry, PageNotFound, useSub } from "../../components";
import { LinearProgress } from '@mui/material';
import { userActions, getCurrentUser, getAlert, commonAction, isProgress, isAdmin, isArchitect, isViewer, encyptDataObject, isLoggedOut } from "../../store";

const MODULE_ID = 'central';
const ConsoleEndPoint = `https://console.${process.env.REACT_APP_MAIN_DOMAIN}`;

export const EditorOnlyRoute = (props) => {
  const { Component, ...rest } = props;
  const user = useSelector(getCurrentUser);
  return (
    //  isViewer(user) ? <Navigate replace to="/page-not-found" /> : <Component {...rest} />
    <Component {...rest} />
  )
}

export const PageNotFoundScreen = (props) => {
  const navigate = useNavigate();
  return <PageNotFound onHome={() => navigate("/")} />
}

export const HomeScreen = (props) => {
  // const {moduleClicked, setModuleClicked} = props
  const { notification, modal } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(getCurrentUser);
  const appAlert = useSelector(getAlert)
  const showProgress = useSelector(isProgress);
  const loggedOut = useSelector(isLoggedOut);
  const { pathname, search } = location;

  const navigateToLogin = () => {
    const redirectData = {};
    let isLocalhost = process.env.REACT_APP_LOCALHOST,
      path = ConsoleEndPoint + '/login', moduleId;

    moduleId = isLocalhost ? 'localhost' : (!loggedOut ? MODULE_ID : null)
    if (moduleId) {
      path += '?source=' + moduleId;
      if (pathname !== '') {
        redirectData.path = pathname;
      }
      if (search !== '') {
        redirectData.search = search;
      }
      if (Object.keys(redirectData).length > 0) {
        let redirect = encyptDataObject(redirectData)
        path += `&redirect=${redirect}`
      }
    }
    window.location.assign(path);
  }
  useSub("SESSION_LOGOUT", () => {
    dispatch(userActions.logout());
  })
  useEffect(() => {
    document.title = 'Central | Staging';
    dispatch(userActions.validateToken())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (loggedOut) {
      navigateToLogin()
    }
  }, [loggedOut])
  useEffect(() => {
    if (appAlert) {
      if (appAlert.type === 'modal') {
        let modalConfig = { title: appAlert.message, content: appAlert.description, okText: 'OK', onOk: clearNotification }
        if (appAlert.severity === 'warning') {
          modal.warning(modalConfig)
        } else if (appAlert.severity === 'error') {
          modal.error(modalConfig)
        } else if (appAlert.severity === 'success') {
          modal.success(modalConfig)
        }
      } else {
        const config = { duration: 2, message: appAlert.message, onClose: clearNotification }
        if (appAlert.success === false) {
          notification.error(config)
        } else {
          notification.success(config)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appAlert]);
  useEffect(() => {
    if (user) {
      if (user === 'Error') {
        navigateToLogin()
      } else {
        console.log(location);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const clearNotification = (e, reason) => {
    if (reason !== 'clickaway') {
      dispatch(commonAction.setAlert(null));
      if (appAlert.type === 'modal') {
        Modal.destroyAll()
      }
    }
  }

  const getTab = () => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    // return currentUrlParams.get('tab') || 'Support'
    return currentUrlParams.get('tab')
  }
  const selectedTab = getTab()

  return (
    <div className='col w-100 h-100 screen o-hide'>
      {
        Boolean(user && user !== 'Error') ?
          <React.Fragment>
            <NavBar user={user} isAdmin={isAdmin(user)} isArchitect={isArchitect(user)} />
            <div className='col flex-full o-auto' style={{ position: 'relative' }}>
              <div className='progress-cnt w-100 '>
                <LinearProgress classes={{ root: `api-progress c1E686D ${showProgress ? 'show' : ''}` }} color="inherit" />
              </div>
              <main className='col flex-full  o-auto'>
                <div className='row w-100 h-100 o-auto'>
                  {/* <SideNavBar pathname={pathname} setModuleClicked={setModuleClicked} moduleClicked={moduleClicked}/> */}
                  <div className=' w-100 h-100 o-auto'>
                    <AppErrorBoundry>
                      {props.children}
                    </AppErrorBoundry>
                  </div>
                </div>
              </main>
            </div>
          </React.Fragment>
          :
          <OvalLoading className='col w-100 h-100' />
      }
    </div>
  )
}
